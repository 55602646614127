@import '../node_modules/@syncfusion/ej2-base/styles/material.css';
@import '../node_modules/@syncfusion/ej2-buttons/styles/material.css';
@import '../node_modules/@syncfusion/ej2-popups/styles/material.css';
@import '../node_modules/@syncfusion/ej2-react-navigations/styles/material.css';
@import "../node_modules/@syncfusion/ej2-react-calendars/styles/material.css";

@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');
:root {
  --black: #242424;
  --dark-grey: #868E91;
  --light-grey: #EFEFEF;
  --white: #ffffff;
  --azure:#788BFF;
  --purple:#B95DFD;
  --green:#2ECD91;
  --violet:#A081FD;
  --celeste:#7BC1F7;
  --light-green:#47D7B6;
}

* body{
  font-family: 'Lato'  !important;
  min-height: 100vh;
  background: linear-gradient(215deg, #18E677, #2BE090,#3EDAA9,#51D4C2,#63CEDB,#76C8F4,#85B8FE,#91A0FE,#9D88FE,#A970FD,#B458FD);
}


* .e-control, .e-css{
  font-family: 'Lato'  !important;
}
.bgWhite{
  background-color: var(--white);
}

.bgGreyTrasparent{ 
  background-color: #afbbbb61;
  
  }

.textGrad{
  background: -webkit-linear-gradient(180deg, #18E677 0%, #2BE090 10%,#3EDAA9 20%,#51D4C2 30%,#63CEDB 40%,#76C8F4 50%,#85B8FE 60%,#91A0FE 70%,#9D88FE 80%,#A970FD 90%,#B458FD 100%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
  width: fit-content;
}

.textBlack{
  color: var(--black);
}
.textGrey{
  color: var(--dark-grey);
}
.textGreen{
 color:var(--green);
}
.textPurple{
  color:var(--purple);
 }
 .textAzure{
  color:var(--azure);
 }
 .textViolet{
  color:var(--violet);
 }
 .textCeleste{
  color:var(--celeste);
 }
 .textLightGreen{
  color:var(--light-green);
 }
 .textWhite{
  color: var(--white);
}
.bold{
  font-weight: 800;
}
.semibold{
  font-weight: 600;
}
.regular{
  font-weight: 400;
}
.txtsize-46{
  font-size: 46px;
}
.txtsize-38{
  font-size: 38px;
}
.txtsize-32{
  font-size: 32px;
}
.txtsize-28{
  font-size: 28px;
}
.txtsize-24{
  font-size: 24px;
}
.txtsize-21{
  font-size: 21px;
}
.txtsize-18{
  font-size: 18px;
}
.txtsize-16{
  font-size: 16px;
}
.txtsize-14{
  font-size: 14px;
}
.txtsize-13{
  font-size: 13px;
}
.txtsize-12{
  font-size: 12px;
}

.img-icon100{
  width: 80px;
  height: 80px;
  object-fit: contain;
  }
.img-icon80{
  width: 80px;
  height: 80px;
  object-fit: contain;
  }
.img-icon60{
  width: 60px;
  height: 60px;
  object-fit: contain;
  }
  .img-icon50{
    height: 50px;
    object-fit: contain;
    }
.img-icon40{
width: 40px;
height: 40px;
object-fit: contain;
}
.img-icon30{
  height: 30px;
  width: 30px;
  object-fit: contain;
  }
.img-icon25{
  height: 25px;
  width: 25px;
  object-fit: contain;
  }
.img-icon20{
  height: 20px;
  width: 20px;
  object-fit: contain;
  }
  .img-icon15{
    height: 15px;
    width: 15px;
    object-fit: contain;
    }
    .img-icon12{
      height: 12px;
      width: 12px;
      object-fit: contain;
      }
.shadowDrop{
  box-shadow: 0 5px 10px #00000033;
}

.borderRadius{
  border-radius: 15px;
}
.e-grid .e-hide {
  display: none;
}
.position-absolute{
  position: absolute;
} 
.pointer{
  cursor: pointer;
}
.borderNone{
  border: none;
}
.textNoselectable {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}



.mt-n6 {
  margin-top: -2.5rem !important;
}

.flex1{
  flex: 1;
}
div.overflowChart{overflow: visible !important;}
div.overflowChart svg{overflow: visible !important;}

