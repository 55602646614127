#navbarStore{
    padding-left: 80px;
}
.iconMainMenu{
    width: 25px;
    height: 25px;
    object-fit: contain;
}
.iconPinMenu{
    height: 25px;
    width: 25px;
    object-fit: contain;
}

