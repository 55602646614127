#mainTab #e-content-mainTab_0 {
    border-radius: 0 15px 15px 15px;
    background-color: rgb(255 255 255 / 50%);
}
#mainTab #e-content-mainTab_3 {
    border-radius: 15px 0 15px 15px;
    background-color: rgb(255 255 255 / 50%);
}
#mainTab #e-content-mainTab_1, #mainTab #e-content-mainTab_2{
    border-radius:15px;
    background-color: rgb(255 255 255 / 50%);
}
#mainTab.e-tab .e-tab-header .e-toolbar-item.e-active {
    background-color: rgb(255 255 255 / 50%);
    border-radius: 20px 20px 0 0;
}

.e-tab .e-tab-header .e-toolbar-item .e-tab-wrap:hover {
    background: transparent;
}

.e-items.e-toolbar-items {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
}



.e-tab .e-tab-header .e-toolbar-item .e-tab-wrap {
    padding: 0;
    background: none;
}


.e-tab .e-tab-header .e-indicator{
    background: none;
}

.e-tab-header h1{
    text-transform: capitalize;
    
}

.e-tab .e-tab-header .e-toolbar-item .e-text-wrap {
    height: initial;
}
.e-tab .e-tab-header .e-toolbar-item.e-active .e-text-wrap {
    height: initial;
}


.e-tab .e-tab-header .e-toolbar-item .e-tab-wrap {
    height: auto;}
 

    .e-tab .e-tab-header .e-toolbar-item:not(.e-separator){
        height: auto;
        min-height: auto;

    }
/* ARROWS */
#mainTab.e-tab .e-tab-header .e-toolbar-items.e-hscroll .e-scroll-nav{
        height: 100%;
    }

    #mainTab .e-tab .e-tab-header .e-scroll-nav .e-nav-arrow,
    .e-tab .e-tab-header .e-hscroll:not(.e-scroll-device)
    .e-scroll-nav .e-nav-arrow{
        color:#fff;
    }

/* BUTTON TAB  */
/* overview */

.overview.headerTabButton {
    background-color: #ffffff;
    border-radius: 17px;
    box-shadow: 0 5px 10px 5px #00000038;
    padding: 5px 15px;
    margin: 15px;
    min-height: 70px;
    min-width: 260px;
    display: flex;
    justify-content: space-around;
    align-items: start;
}


.overview h1 {
    margin-top: 8px;
        background: -webkit-linear-gradient(180deg, #18E677, #2BE090,#3EDAA9,#51D4C2,#63CEDB,#76C8F4,#85B8FE,#91A0FE,#9D88FE,#A970FD,#B458FD);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }

      .overview span {
        background-position:center;
        height: 55px;
        width: 55px;
        display: block;
        background-repeat: no-repeat;
        background-size: contain;
          background-image: url("../../../img/IconOverview.png");
      }


      .e-toolbar-item.e-active  .overview, .overview:hover{
        background: -webkit-linear-gradient(180deg, #18E677, #2BE090,#3EDAA9,#51D4C2,#63CEDB,#76C8F4,#85B8FE,#91A0FE,#9D88FE,#A970FD,#B458FD);
        }   
        .e-toolbar-item.e-active .overview span,.overview:hover span {
              background-image: url("../../../img/IconOverviewWhite.png");
          }

     .e-toolbar-item.e-active  .overview h1, .overview:hover  h1{
        -webkit-text-fill-color:#fff;
        color:#fff;
    }

/* recessed */
.recessed.headerTabButton {
    background-color: #ffffff;
    border-radius: 17px;
    box-shadow: 0 5px 10px 5px #00000038;
    padding: 5px 15px;
    margin: 15px;
    min-height: 70px;
    min-width: 260px;
    display: flex;
    justify-content: space-around;
    align-items: start;
}


.recessed h1 {
        margin-top: 8px;
        background: -webkit-linear-gradient(270deg, #5CE8C4, #58E6BF,#53E3BA,#4FE0B4,#4ADDAF,#46DBAA,#41D8A5,#3DD5A0,#38D29B,#33D096,#2FCD91);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }

      .recessed span {
        background-position:center;
        height: 55px;
        width: 55px;
        display: block;
        background-repeat: no-repeat;
        background-size: contain;
          background-image: url("../../../img/IconRecessed.png");
      }


      .e-toolbar-item.e-active  .recessed, .recessed:hover{
        background: -webkit-linear-gradient(270deg, #5CE8C4, #58E6BF,#53E3BA,#4FE0B4,#4ADDAF,#46DBAA,#41D8A5,#3DD5A0,#38D29B,#33D096,#2FCD91);
        }   
        .e-toolbar-item.e-active .recessed span,.recessed:hover span {
              background-image: url("../../../img/IconRecessedWhite.png");
          }

     .e-toolbar-item.e-active  .recessed h1, .recessed:hover  h1, .e-toolbar-item.e-active  .recessed p, .recessed:hover  p{
        -webkit-text-fill-color:#fff;
        color:#fff;
    }

    

    /* sensory */
.sensory.headerTabButton {
    background-color: #ffffff;
    border-radius: 17px;
    box-shadow: 0 5px 10px 5px #00000038;
    padding: 5px 15px;
    margin: 15px;
    min-height: 70px;
    min-width: 260px;
    display: flex;
    justify-content: space-around;
    align-items: start;
}


.sensory h1 {
        margin-top: 8px;
        background: -webkit-linear-gradient(270deg, #98D2FF, #95CAFF,#91C2FF,#8DB9FF,#8AB1FF,#86A9FF,#82A1FF,#7F99FF,#7B91FF,#7789FF,#7481FF);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }

      .sensory span {
        background-position:center;
        height: 55px;
        width: 55px;
        display: block;
        background-repeat: no-repeat;
        background-size: contain;
          background-image: url("../../../img/IconSensor.png");
      }


      .e-toolbar-item.e-active  .sensory, .sensory:hover{
        background: -webkit-linear-gradient(270deg, #98D2FF, #95CAFF,#91C2FF,#8DB9FF,#8AB1FF,#86A9FF,#82A1FF,#7F99FF,#7B91FF,#7789FF,#7481FF);
        }   
        .e-toolbar-item.e-active .sensory span,.sensory:hover span {
              background-image: url("../../../img/IconSensorWhite.png");
          }

     .e-toolbar-item.e-active  .sensory h1, .sensory:hover  h1, .e-toolbar-item.e-active  .sensory p, .sensory:hover  p{
        -webkit-text-fill-color:#fff;
        color:#fff;
    }

    .sensory span.iconPeople {
        vertical-align: text-bottom;
        background-position:center;
        height: 21px;
        width: 24px;
        display: inline-block;
        background-repeat: no-repeat;
        background-size: contain;
          background-image: url("../../../img/IconBlackPeople.png");
      }
      .e-toolbar-item.e-active .sensory span.iconPeople,.sensory:hover span.iconPeople {
          background-image: url("../../../img/IconWhitePeople.png");
      }

    /* ecommerce */
.ecommerce.headerTabButton {
    background-color: #ffffff;
    border-radius: 17px;
    box-shadow: 0 5px 10px 5px #00000038;
    padding: 5px 15px;
    margin: 15px;
    min-height: 70px;
    min-width: 260px;
    display: flex;
    justify-content: space-around;
    align-items: start;
}


.ecommerce h1 {
        margin-top: 8px;
        background: -webkit-linear-gradient(270deg, #DB91FF, #D78BFF,#D486FF,#D080FE,#CC7AFE,#C874FE,#C46FFE,#C069FE,#BD63FD,#B95EFD,#B558FD);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }

      .ecommerce span {
        background-position:center;
        height: 55px;
        width: 55px;
        display: block;
        background-repeat: no-repeat;
        background-size: contain;
          background-image: url("../../../img/IconEcommercePurple.png");
      }


      .e-toolbar-item.e-active  .ecommerce, .ecommerce:hover{
        background: -webkit-linear-gradient(270deg, #DB91FF, #D78BFF,#D486FF,#D080FE,#CC7AFE,#C874FE,#C46FFE,#C069FE,#BD63FD,#B95EFD,#B558FD);
        }   
        .e-toolbar-item.e-active .ecommerce span,.ecommerce:hover span {
              background-image: url("../../../img/IconEcommerceWhite.png");
          }

     .e-toolbar-item.e-active  .ecommerce h1, .ecommerce:hover  h1, .e-toolbar-item.e-active  .ecommerce p, .ecommerce:hover  p{
        -webkit-text-fill-color:#fff;
        color:#fff;
    }