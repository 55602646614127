.legendComparisonStore{
    width:20px;
    height: 20px;
    border-radius: 100%;
    background: -webkit-linear-gradient(
        270deg
        , #98D2FF, #95CAFF,#91C2FF,#8DB9FF,#8AB1FF,#86A9FF,#82A1FF,#7F99FF,#7B91FF,#7789FF,#7481FF);}
.legendComparisonOnline{
    width:20px;
    height: 20px;
    border-radius: 100%;
    background: -webkit-linear-gradient(
270deg
, #DB91FF, #D78BFF,#D486FF,#D080FE,#CC7AFE,#C874FE,#C46FFE,#C069FE,#BD63FD,#B95EFD,#B558FD);
}
/* TOOLTIP */
path#overviewTrafficComparison_tooltip_path{
    fill: #d6d6d699;
    stroke: none;
}
text#overviewTrafficComparison_tooltip_text tspan{
font-weight: bold;
    fill:#363636;
} 

 #overviewTrafficComparison_Secondary_Element text#overviewTrafficComparison_tooltip_text tspan:nth-child(1){
    font-weight: bold;
    }

/* g#overviewTrafficComparison_tooltip_trackball_group {
    display: none;
} */