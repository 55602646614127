

#lineChartTooltip g path{
opacity: 1 !important;}
#lineChartTooltip g path:hover{
    opacity: .85 !important;}

/* TOOLTIP  */
    #lineChartTooltip{
        background-color: #e6e9e7e0;
    }


    #lineChartTooltip .tooltipHeader{
        padding-bottom: 3px;
        border-bottom: 2px solid #868D91;
    }



