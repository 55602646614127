#sideBar{
    position: fixed;
    top: 0;
     width: 75px;
    height: 100vh;
     z-index: 1000000000;
    background-color: #fff;
    border-radius: 0 ;
    box-shadow: 2px 0px 2px 4px #00000040;
    overflow: hidden;

}
.innerContainerSideBar{
    height: 100%;
    display: flex;
    padding: 20px 0;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
    overflow-y: auto;
}
.logoSaga{
    height: 45px;
    width:  45px;
    object-fit: contain;
    margin-bottom: 80px;
}

.StoreSelect{
    margin-bottom: 20px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    align-items: center;
}
.squareStoreSelect{
    background:  linear-gradient(215deg, #18E677, #2BE090,#3EDAA9,#51D4C2,#63CEDB,#76C8F4,#85B8FE,#91A0FE,#9D88FE,#A970FD,#B458FD);
    width: 37px;
    height: 37px;
    text-align: center;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    border: none;
    }
    .squareStoreSelect:active{
        padding-top: 3px;
    }
    .squareStoreSelect:hover{
        opacity: 0.8;
    }


.StoreSelect .nameStore{
    font-size: 10px;
    font-weight:700;
    padding: 5px 9px;
    line-height: 1.1;
    color:var(--textPrimary);
    margin-bottom: 0;
}
.IconPlusAddStore{
    width: 35px;
height: 35px;
}

/* SCROLLBAR */
#sideBar {
    --scrollbarBG: #FFF;
    --thumbBG: #90A4AE;
  }
  .innerContainerSideBar::-webkit-scrollbar {
    width: 11px;
  }
  .innerContainerSideBar {
    scrollbar-width: thin;
    scrollbar-color: var(--thumbBG) var(--scrollbarBG);
  }
  .innerContainerSideBar::-webkit-scrollbar-track {
    background: var(--scrollbarBG);
  }
  .innerContainerSideBar::-webkit-scrollbar-thumb {
    background-color: var(--thumbBG) ;
    border-radius: 6px;
    border: 3px solid var(--scrollbarBG);
  }