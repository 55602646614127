/* td.e-rowcell.e-templatecell{
    border-bottom: 2px solid var(--light-grey);
}
td.e-rowcell.e-templatecell:first-child{
   border-bottom:none; 
}

.e-headercelldiv span.e-headertext{
    color: var(--dark-grey);
    font-size: 14px;
    font-weight: 600;
}
 */

.minHeightCard{
    min-height: 480px;
}
.e-gridheader.e-lib.e-droppable{
    display: none;
}

.borderBottomGrey{
    border-bottom: 2px solid var(--light-grey);
}