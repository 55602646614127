.lang-list {
    position: absolute;
    bottom: 0;
}
 
.lang-list .lang-item {
    padding: 0;
    border: none;
    background: none;
}
 
.lang-list .lang-item:not(.active) {
    display: none;
}
 
.lang-list:hover .lang-item {
    display: inherit;
    opacity: .5;
}
 
.lang-list:hover .lang-item:hover {
    opacity: 1;
}
 
.lang-list .lang-item:hover {
    background-color: var(--prussia);
}
 
.lang-list .lang-item img {
    width: 100%;
}