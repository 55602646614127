.ecommerceHeaderTabButton {
    display: flex;
flex-direction: column;
align-content: center;
align-items: center;
justify-content: center;
    background-color: #ffffff;
    border-radius: 17px;
    box-shadow: 0 3px 5px 0px #00000029;
    padding: 5px ;
    margin: 15px 7px;
    min-height: 90px;
    width: 90px;
    white-space: break-spaces;
}

#mainTab #tabTargetEcommerce .e-tab-header .e-toolbar-item.e-active{ 
    background-color:transparent;
    border-radius: 0;
}
.ecommerceHeaderTabButton h1 {
        margin-top: 8px;
        background: -webkit-linear-gradient(270deg, #DB91FF, #D78BFF,#D486FF,#D080FE,#CC7AFE,#C874FE,#C46FFE,#C069FE,#BD63FD,#B95EFD,#B558FD);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }

      .e-toolbar-item.e-active  .ecommerceHeaderTabButton, .ecommerceHeaderTabButton:hover{
       
        background: -webkit-linear-gradient(270deg, #DB91FF, #D78BFF,#D486FF,#D080FE,#CC7AFE,#C874FE,#C46FFE,#C069FE,#BD63FD,#B95EFD,#B558FD);
        }   
     
      .ecommerceHeaderTabButton span {
        background-position:center;
        height: 40px;
        width: 40px;
        display: block;
        background-repeat: no-repeat;
        background-size: contain;
      }

      .e-toolbar-item.e-active  .ecommerceHeaderTabButton h1, .ecommerceHeaderTabButton:hover  h1, .e-toolbar-item.e-active  .ecommerceHeaderTabButton p, .ecommerceHeaderTabButton:hover  p{
        -webkit-text-fill-color:#fff;
        color:#fff;
    
    }


     .e-toolbar-item.e-active  .ecommerceHeaderTabButton h1, .ecommerceHeaderTabButton:hover  h1, .e-toolbar-item.e-active  .ecommerceHeaderTabButton p, .ecommerceHeaderTabButton:hover  p{
        -webkit-text-fill-color:#fff;
        color:#fff;
    
    }


/* ICON TAB AND HOVER/ACTIVE */
    .ecommerceHeaderTabButton span.shoppingCartTI {
          background-image: url("../../../img/IconPurpleShoppingCart.png");
      }
    .e-toolbar-item.e-active .ecommerceHeaderTabButton span.shoppingCartTI , .ecommerceHeaderTabButton:hover span.shoppingCartTI {
        background-image: url("../../../img/IconWhiteShoppingCart.png");
    }


    .ecommerceHeaderTabButton span.reveneuTI {
        background-image: url("../../../img/IconPurpleMoney.png");
    }
  .e-toolbar-item.e-active .ecommerceHeaderTabButton span.reveneuTI , .ecommerceHeaderTabButton:hover span.reveneuTI {
      background-image: url("../../../img/IconWhiteMoney.png");
  }

  .ecommerceHeaderTabButton span.userTI {
    background-image: url("../../../img/IconPurpleUser.png");
}
.e-toolbar-item.e-active .ecommerceHeaderTabButton span.userTI , .ecommerceHeaderTabButton:hover span.userTI {
  background-image: url("../../../img/IconWhiteUser.png");
}

.ecommerceHeaderTabButton span.transactionTI {
    background-image: url("../../../img/IconPurpleTransaction.png");
}
.e-toolbar-item.e-active .ecommerceHeaderTabButton span.transactionTI , .ecommerceHeaderTabButton:hover span.transactionTI {
  background-image: url("../../../img/IconWhiteTransaction.png");
}

.ecommerceHeaderTabButton span.transactionTI {
    background-image: url("../../../img/IconPurpleTransaction.png");
}
.e-toolbar-item.e-active .ecommerceHeaderTabButton span.transactionTI , .ecommerceHeaderTabButton:hover span.transactionTI {
  background-image: url("../../../img/IconWhiteTransaction.png");
}

.ecommerceHeaderTabButton span.transactionAverageTI {
    background-image: url("../../../img/IconPurpleTransactionAverage.png");
}
.e-toolbar-item.e-active .ecommerceHeaderTabButton span.transactionAverageTI , .ecommerceHeaderTabButton:hover span.transactionAverageTI {
  background-image: url("../../../img/IconWhiteTransactionAverage.png");
}


.ecommerceHeaderTabButton span.sessionTI {
    background-image: url("../../../img/IconPurpleSession.png");
}
.e-toolbar-item.e-active .ecommerceHeaderTabButton span.sessionTI , .ecommerceHeaderTabButton:hover span.sessionTI {
  background-image: url("../../../img/IconWhiteSession.png");
}



#tabTargetEcommerce .e-tab-header .e-toolbar-items.e-hscroll .e-scroll-nav{
  height: 100%;
  width: 20px;
  margin: 0;
  padding: 0;
}

#tabTargetEcommerce .e-hscroll:not(.e-scroll-device){
  padding: 0 20px;
}