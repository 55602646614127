
.sectionTargetSuccess{
    background-repeat: no-repeat;
    background-position:center right;
    background-size: contain;
    background-image: url("../../../../img/IconTargetSuccess.png");
}


/* #targetCharttransactionEcommerce
#targetChartreveneuEcommerceSecondary_Element{
    left: 0 !important;
    height: 100%;
    display: block;
    width: 100%;
}
#targetCharttransactionEcommerce #targetChartreveneuEcommerceAnnotation_collections{
  position: relative !important;  
}

#targetCharttransactionEcommerce div#targetChartreveneuEcommerceAnnotation0{
    position: relative!important;

 left: 0 !important;
top: 98px !important;
transform: none !important;

width: 100%;
text-align: -webkit-center;} */