.attendanceHeaderTabButton {
    display: flex;
flex-direction: column;
align-content: center;
align-items: center;
justify-content: center;
    background-color: #ffffff;
    border-radius: 17px;
    box-shadow: 0 3px 5px 0px #00000029;
    padding: 5px ;
    margin: 15px 7px;
    min-height: 90px;
    width: 100%;
    white-space: break-spaces;
}

#mainTab #tabTargetAttendance .e-tab-header .e-toolbar-item.e-active{ 
    background-color:transparent;
    border-radius: 0;
    width: 100%;
}
#mainTab #tabTargetAttendance  .e-text-wrap{
    width: 100%;

}

#mainTab #tabTargetAttendance  .e-text-wrap  span.e-tab-text{
    width: 100%;

}


.attendanceHeaderTabButton h1 {
        margin-top: 8px;
        background: -webkit-linear-gradient(
            270deg
            , #98D2FF, #95CAFF,#91C2FF,#8DB9FF,#8AB1FF,#86A9FF,#82A1FF,#7F99FF,#7B91FF,#7789FF,#7481FF);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }

      .e-toolbar-item.e-active  .attendanceHeaderTabButton, .attendanceHeaderTabButton:hover{
        background: -webkit-linear-gradient( 270deg, #98D2FF, #95CAFF,#91C2FF,#8DB9FF,#8AB1FF,#86A9FF,#82A1FF,#7F99FF,#7B91FF,#7789FF,#7481FF);
    }   

      .attendanceHeaderTabButton span {
        background-position:center;
        height: 40px;
        width: 40px;
        display: block;
        background-repeat: no-repeat;
        background-size: contain;
      }

      .e-toolbar-item.e-active  .attendanceHeaderTabButton h1, .attendanceHeaderTabButton:hover  h1, .e-toolbar-item.e-active  .attendanceHeaderTabButton p, .attendanceHeaderTabButton:hover  p{
        -webkit-text-fill-color:#fff;
        color:#fff;
    
    }


     .e-toolbar-item.e-active  .attendanceHeaderTabButton h1, .attendanceHeaderTabButton:hover  h1, .e-toolbar-item.e-active  .attendanceHeaderTabButton p, .attendanceHeaderTabButton:hover  p{
        -webkit-text-fill-color:#fff;
        color:#fff;
    
    }


/* ICON TAB AND HOVER/ACTIVE */
    .attendanceHeaderTabButton span.dailyAttendaceTI {
          background-image: url("../../../img/IconPurpleShoppingCart.png");
      }
    .e-toolbar-item.e-active .attendanceHeaderTabButton span.dailyAttendaceTI , .attendanceHeaderTabButton:hover span.dailyAttendaceTI {
        background-image: url("../../../img/IconWhiteShoppingCart.png");
    }

