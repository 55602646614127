.gradient-input {
  display: flex;
  position: relative;
  padding: 1px;
  box-sizing: border-box;
  width: fit-content;
  background: linear-gradient(to left, #18E677, #2BE090, #3EDAA9, #51D4C2, #63CEDB, #76C8F4, #85B8FE, #91A0FE, #9D88FE, #A970FD, #B458FD);
  border-radius: 7px;

 }
 .gradient-input .e-input {
  border-radius: 6px;
  border:none;
 }
 .gradient-input.inputShowHide .e-input {
  border-radius: 6px 0 0 6px;
 }
 .gradient-input.inputShowHide span{
  border-radius: 0 6px 6px 0;
 }
 .gradient-input .e-input:focus-visible {
  color:var(--dark-grey);
  background-color: #fff;
  -webkit-appearance: none;
  outline: none;
 }
 .gradientButton{
  text-transform: capitalize;
   color:var(--white);
  background: linear-gradient(215deg, #18E677 0%, #2BE090, #3EDAA9, #51D4C2, #63CEDB, #76C8F4, #85B8FE, #91A0FE, #9D88FE, #A970FD, #B458FD 100%);
  border-radius: 8px;
  background-repeat: no-repeat;
  border:none;
  -webkit-transition: opacity .3s ease-out;  
  -moz-transition:  opacity .3s ease-out;  
  -o-transition:  opacity .3s ease-out;  
  transition:  opacity .3s ease-out;  
  width: fit-content;
  align-self: center;
  padding: 8px 50px;
 }
 .gradientButton:hover{
  color:var(--white);
   opacity:.9;
   }

   .gradientButton:focus{
    color:var(--white);
    opacity:1;
     }
 .gradientButton:disabled{
  color:var(--white);
  opacity:.5;
 }
