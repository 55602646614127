#dateSelector{
  background-color: #ffffffcc;}
  .stv-radio-button {
    position: absolute;
    left: -9999em;

}

.stv-radio-buttons-wrapper .buttonRadio {
      float: left;
      margin-right: -1px;
      border: 1px solid  var(--dark-grey);
}


.stv-radio-buttons-wrapper .buttonRadio:first-child{
  border-bottom-left-radius: 10px ;
  border-top-left-radius: 10px;
  
    }
    .stv-radio-buttons-wrapper .buttonRadio:last-child{
      border-bottom-right-radius: 10px ;
      border-top-right-radius: 10px;
      
        }

     .stv-radio-button + label {
     
      padding: .5em 1em;
      cursor: pointer;
    }

    .stv-radio-button:checked + label {
      background-color: var(--dark-grey);
      color:#fff;
    }

    .buttonRadio:first-child  .stv-radio-button:checked + label{
      border-bottom-left-radius: 9px ;
      border-top-left-radius: 9px;
  }


  .buttonRadio:last-child  .stv-radio-button:checked + label{
    border-bottom-right-radius: 9px ;
    border-top-right-radius: 9px;
}


/* PICK DATE */
#dateSelector  span.e-input-group.e-control-wrapper.e-date-wrapper{
  display: flex;
}
#dateSelector span.e-input-group-icon.e-date-icon.e-icons{
  margin: 0;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #868e91;
  padding: 0 10px;
  border-radius: 0 7px 7px 0;
  display: flex;
  justify-content: center;
  align-items: center;
cursor: pointer;
}
#dateSelector .datepicker-control-section span.e-input-group.e-control-wrapper.e-date-wrapper.e-valid-input {
  height: 40px;
}


#dateSelector  input#calendar{
  text-transform: capitalize;
  padding: 8px;
    border: 1px solid #868e91;
    border-radius: 7px 0 0 7px;}


    /* Calendar Style */

 

      .e-calendar .e-content td.e-today.e-selected span.e-day,
  .e-calendar .e-content .e-selected span.e-day {
  background: linear-gradient(215deg, #18E677, #2BE090,#3EDAA9,#51D4C2,#63CEDB,#76C8F4,#85B8FE,#91A0FE,#9D88FE,#A970FD,#B458FD) !important;
    border-color: black !important;
    color: var(--white) !important;
    border-radius: 5px;
}

  .e-calendar .e-content td.e-focused-date.e-today span.e-day {
    border-color:  var(--azure);
    color: var(--black);
    border-radius: 5px;
}

 .e-calendar .e-content td.e-today span.e-day {
  border-color:  var(--azure);
  color: var(--black);
  border-radius: 5px;
}
 .e-calendar .e-content td.e-today:hover span.e-day {
  border-color:  var(--azure);
  color: var(--black);
  border-radius: 5px;
}
 .e-btn.e-today.e-flat.e-primary.e-css {
    background-color:  var( --dark-grey);
    border-radius: 5px;
    color: var(--white);
}
