.rowInnerCard{
    display: flex;
    flex-wrap: wrap;
}

.iconTotalMoney {
    height: 25px;
    object-fit: contain;
}
.iconStoreGreen, .iconEcommercePurple{
    height: 35px;
    object-fit: contain;
}

.borderRight:first-child{
     border-right: #EDEDED 3px solid;;
}


.sectionRevenueForClient {
    border-right: #EDEDED 3px solid;
}
.sectionRevenueForClient:last-child{
    border-right: none;
}
.pieChartContent{
    max-width: 280px;
    width: 100%;
}
g#overviewTrafficComparisonAxisLabels1 text{
    fill:#7B93FF
}
g#overviewTrafficComparisonAxisLabels2 text{
    fill:#C06AFD
}
/* On screens that are 600px or less, set the background color to olive */
@media screen and (max-width: 767px) {
    .sectionRevenueForClient {
        border-right: none;
    }
  }