.storeHeaderTabButton {
    display: flex;
flex-direction: column;
align-content: center;
align-items: center;
justify-content: center;
    background-color: #ffffff;
    border-radius: 17px;
    box-shadow: 0 3px 5px 0px #00000029;
    padding: 5px ;
    min-height: 90px;
    width: 90px;
    white-space: break-spaces;
}

#mainTab #tabTargetStore .e-tab-header .e-toolbar-item.e-active{ 
    background-color:transparent;
    border-radius: 0;
}
.storeHeaderTabButton h1 {
        margin-top: 8px;
        background: -webkit-linear-gradient(
            270deg
            , #5CE8C4, #58E6BF,#53E3BA,#4FE0B4,#4ADDAF,#46DBAA,#41D8A5,#3DD5A0,#38D29B,#33D096,#2FCD91);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }

      .e-toolbar-item.e-active  .storeHeaderTabButton, .storeHeaderTabButton:hover{
       
        background: -webkit-linear-gradient(
            270deg
            , #5CE8C4, #58E6BF,#53E3BA,#4FE0B4,#4ADDAF,#46DBAA,#41D8A5,#3DD5A0,#38D29B,#33D096,#2FCD91);
        }   
     
      .storeHeaderTabButton span {
        background-position:center;
        height: 40px;
        width: 40px;
        display: block;
        background-repeat: no-repeat;
        background-size: contain;
      }

      .e-toolbar-item.e-active  .storeHeaderTabButton h1, .storeHeaderTabButton:hover  h1, .e-toolbar-item.e-active  .storeHeaderTabButton p, .storeHeaderTabButton:hover  p{
        -webkit-text-fill-color:#fff;
        color:#fff;
    
    }




/* ICON TAB AND HOVER/ACTIVE */
    .storeHeaderTabButton span.reveneuTI {
          background-image: url("../../../img/IconGreenReveneu.png");
      }
    .e-toolbar-item.e-active .storeHeaderTabButton span.reveneuTI , .storeHeaderTabButton:hover span.reveneuTI {
        background-image: url("../../../img/IconWhiteMoney.png");
    }


    .storeHeaderTabButton span.transactionTI {
        background-image: url("../../../img/IconGreenTicket.png");
    }
  .e-toolbar-item.e-active .storeHeaderTabButton span.transactionTI , .storeHeaderTabButton:hover span.transactionTI {
      background-image: url("../../../img/IconWhiteTransaction.png");
  }

   .storeHeaderTabButton span.transactionAverageTI {
    background-image: url("../../../img/IconGreenTicketAverage.png");
} 
.e-toolbar-item.e-active .storeHeaderTabButton span.transactionAverageTI , .storeHeaderTabButton:hover span.transactionAverageTI {
  background-image: url("../../../img/IconWhiteTransactionAverage.png");
}


