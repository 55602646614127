.lineGradUnderLabel:after {
    content: "";
    height: 6px;
    width: 100%;
    display: block;
    margin-top: 3px;
}
.shb-radio-button + label{
    cursor: pointer;
    min-width: 60px;
}

    .shb-radio-button {
      position: absolute;
      left: -9999em;
  
  }
  
  