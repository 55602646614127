
#pieChart {
    overflow: hidden;
    place-content: center;
}

#pieChart g path{
opacity: 1 !important;}
#pieChart g path:hover{
    opacity: .85 !important;}

/* TOOLTIP  */
    #pieChartTooltip{
        background-color: #e6e9e7e0;
    }


    #pieChartTooltip .tooltipHeader{
        padding-bottom: 3px;
        border-bottom: 2px solid #868D91;
    }

    #pieChartTooltip .iconStore{
        width: 20px;
        height: 20px;
        object-fit: cover;
    }



